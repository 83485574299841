import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  Domain2Service,
  LanguageService,
  StorageService,
  TEMP_KEYS,
  TEMP_KEY_PREFIX,
  VOLATILE_KEYS
} from '@services/public';
import { GOAL_PAGE, GOAL_PREVIOUS_PAGE, GoalData } from './common';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SKILL_BUILDER_TYPES, SkillBuilderService } from '@services/member';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { APIErrors, isAPIErrors } from '@util';

const PAGE_PATH: string = 'skillBuilders.goal.pages.main';

@Component({
  selector: 'app-member-skill-builder-goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.scss']
})
export class MemberSkillBuilderGoalComponent
  implements OnInit, OnDestroy
{

  @ViewChild('dailyCompletedTemplate', { static: false })
    dailyCompletedTemplate: TemplateRef<void>;
  GOAL_PAGE: typeof GOAL_PAGE = GOAL_PAGE;
  data: GoalData;
  modalRef: BsModalRef;
  private _subscriptions: Subscription = new Subscription();

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    reminder: '',
    ok: ''
  }

  constructor(
    private _domainSvc: Domain2Service,
    private _languageSvc: LanguageService,
    private _modalSvc: BsModalService,
    private _router: Router,
    private _skillBuilderSvc: SkillBuilderService,
    private _storageSvc: StorageService
  ) { }

  async ngOnInit(): Promise<void> {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] === 'object' &&
          value[PAGE_PATH] !== null
        ) this.page = value[PAGE_PATH];
      }
    );

    // Load domain and skill data to be used in subpages.
    await this._domainSvc.loaded;

    // Get goal data.
    this.data = this._storageSvc.getTempStorage(TEMP_KEYS.GOAL);
    const goalPlanUUID: string =
      this._storageSvc.getVolatileStorage(VOLATILE_KEYS.GOAL_PLAN_UUID, true);
    if (!this.data) {
      this._skillBuilderSvc.getStatus(
        SKILL_BUILDER_TYPES.GOAL,
        goalPlanUUID
      ).subscribe(
        (res: GoalData | APIErrors) => {
          if (!isAPIErrors(res) && !this.data) {
            // Check for daily completed.
            if (res.dailyCompleted) {
              this.modalRef = this._modalSvc.show(this.dailyCompletedTemplate, {
                class: "modal-sm modal-dialog-centered",
                backdrop: "static",
                keyboard: true,
              });
              return;
            }

            // Handle track redirects.
            if (!res.start && res.skillBuilderType !== 'goal') {
              this._storageSvc.setVolatileStorage(
                VOLATILE_KEYS.GOAL_PLAN_UUID, res.goalPlanUUID);
              this._router.navigate(
                ['member/skill-builder', res.skillBuilderType]
              );
            }

            if (res.start) {
              this.data = {
                ...res,
                page: GOAL_PAGE.DOMAIN
              };
            } else if (res.yesterdayCompleted) {
              this.data = {
                ...res,
                page: GOAL_PAGE.TRACK,
                yesterday: false
              };
            } else {
              this.data = {
                ...res,
                page: GOAL_PAGE.YESTERDAY
              };
            }

            this._setStorageSubscription();
          }
        }
      );
    } else this._setStorageSubscription();
  }

  private _setStorageSubscription() {
    this._subscriptions.add(this._storageSvc.updates.subscribe(update => {
      if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.ALL)
        this.data = update.value[TEMP_KEYS.GOAL];
      if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.CLEAR)
        this.data = null;
      if (update.key === TEMP_KEY_PREFIX + TEMP_KEYS.GOAL)
        this.data = update.value;
      if (!this.data && !this._router.getCurrentNavigation())
        this._router.navigate(['member/dashboard']);
    }));
  }

  back() {
    const page: GOAL_PAGE = GOAL_PREVIOUS_PAGE[this.data.page];
    if (
      !page || (page === GOAL_PAGE.YESTERDAY && this.data.yesterdayCompleted)
    ) {
      this._router.navigate(['member/dashboard']);
      return;
    }
    this._storageSvc.setTempStorage(TEMP_KEYS.GOAL, { ...this.data, page });
  }

  closePopup() {
    this.modalRef?.hide();
    this._router.navigate(['member/dashboard']);
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    this._storageSvc.setTempStorage(TEMP_KEYS.GOAL, null);
  }

}
