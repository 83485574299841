import { Component, Input, OnInit } from '@angular/core';
import { BRAIN_PAGE, BrainData } from '../common';
import { LanguageService, StorageService, TEMP_KEYS } from '@services/public';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BrainService } from '@services/member/skill-builder/brain.service';
import { APIErrors, APISuccess, isAPISuccess } from '@util';

const PAGE_PATH: string = 'skillBuilders.brain.pages.impact';

@Component({
  selector: 'app-member-skill-builder-brain-impact',
  templateUrl: './impact.component.html',
  styleUrls: ['./impact.component.scss']
})
export class MemberSkillBuilderBrainImpactComponent implements OnInit {

  @Input() data: BrainData;
  choice: boolean | null = null;
  form: UntypedFormGroup;
  ratingValue: number;
  submitted = false;

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    no: '',
    yes: '',
    error1: '',
    ratingTitle: '',
    noImpact: '',
    bigImpact: '',
    error2: '',
    next: ''
  }

  constructor(
    private _storageSvc: StorageService,
    private _languageSvc: LanguageService,
    private _brainSvc: BrainService
  ) {}

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get(
      [PAGE_PATH],
      { [PAGE_PATH]: { doTomorrow: this.data.doTomorrow } }
    ).then(
      value => {
        if (
          typeof value[PAGE_PATH] === 'object' &&
          value[PAGE_PATH] !== null
        ) this.page = value[PAGE_PATH];
      }
    );

    this.form = new UntypedFormGroup({
      rating: new UntypedFormControl(
        this.data?.impact || '',
        Validators.required
      )
    });
  }

  next() {
    this.submitted = true;
    if (this.choice === null || (this.choice && !this.form.valid)) return;

    const data: BrainData = Object.assign({}, this.data, {
      completed: this.choice,
      impact: this.form.controls.rating.value || null
    });
    this._brainSvc.updateLog(data).subscribe(
      (res: APISuccess | APIErrors) => {
        if (isAPISuccess(res)) {
          this._storageSvc.setTempStorage(
            TEMP_KEYS.BRAIN,
            {
              ...this.data,
              page: (
                this.data.yesterdayCompleted ?
                BRAIN_PAGE.AFFIRMATIONS :
                BRAIN_PAGE.YESTERDAY
              ),
              doTomorrow: null
            }
          );
        }
      });
  }

}
